<div class="container d-flex  flex-column justify-content-center">
  <div class="row align-self-end">
    <app-language></app-language>
  </div>
  <div class="row title  align-self-center">
    <h1><app-icon [width]="80"></app-icon>{{title}}</h1>
  </div>
  <div class="row card align-self-center">
    <div class="card-body">
      <div *ngIf="(shareService.currentSharing | async) == shareService.UnknownSharingKey"
           class="h3 text-center text-danger" i18n>Unknown sharing key.
      </div>
      <form *ngIf="(shareService.currentSharing | async) != shareService.UnknownSharingKey"
            name="form" id="form" class="form-horizontal" #LoginForm="ngForm" (submit)="onLogin()">
        <div class="error-message" [hidden]="loginError==false" i18n>Wrong password</div>


        <div class="input-group mb-3">
          <div class="input-group-text"><ng-icon name="ionLockClosedOutline"></ng-icon></div>
          <input type="password"
                 i18n-placeholder
                 class="form-control"
                 name="password"
                 id="password"
                 placeholder="Password"
                 autocomplete="login-password"
                 autocorrect="off"
                 autocapitalize="none"
                 [(ngModel)]="password"
                 required>
        </div>

        <div class="col-sm-12 controls d-grid gap-2">
          <button class="btn btn-primary btn-lg"
                  [disabled]="!LoginForm.form.valid || inProgress"
                  type="submit"
                  name="action" i18n>Enter
          </button>
        </div>
      </form>

    </div>
  </div>
</div>

