<div class="container d-flex  flex-column justify-content-center">
  <div class="row align-self-end">
    <app-language></app-language>
  </div>
  <div class="row title  align-self-center">
    <h1>
      <app-icon [width]="80"></app-icon>
      {{title}}</h1>
  </div>
  <div class="row card align-self-center">
    <div class="card-body">
      <h4 class="card-title" i18n>Please log in</h4>
      <form name="form" id="form" #LoginForm="ngForm" (submit)="onLogin()">
        <div class="error-message" [hidden]="loginError==false" i18n>
          Wrong username or password
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text"><ng-icon name="ionPersonOutline"></ng-icon></div>
          <input type="text"
                 i18n-placeholder
                 class="form-control"
                 name="username"
                 id="username"
                 autocomplete="login-username"
                 [(ngModel)]="loginCredential.username"
                 placeholder="Username"
                 required>
        </div>

        <div class="input-group mb-3">
          <div class="input-group-text"><ng-icon name="ionLockClosedOutline"></ng-icon></div>
          <input type="password"
                 i18n-placeholder
                 class="form-control"
                 name="password"
                 id="password"
                 placeholder="Password"
                 autocomplete="login-password"
                 autocorrect="off"
                 autocapitalize="none"
                 [(ngModel)]="loginCredential.password"
                 required>
        </div>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" name="rememberMe" id="rememberMe"
                 [(ngModel)]="loginCredential.rememberMe">
          <label class="form-check-label" for="rememberMe" i18n>Remember me</label>
        </div>

        <!-- Button -->
        <div class="col-sm-12 controls d-grid gap-2">
          <button class="btn btn-primary btn-lg"
                  [disabled]="!LoginForm.form.valid || inProgress"
                  type="submit"
                  name="action" i18n>Login
          </button>
        </div>
      </form>

    </div>
  </div>
</div>
