<div class="input-group" style="border-radius: inherit">
  <input type="text"
         class="form-control search-text"
         [placeholder]="placeholder"
         (keyup)="onSearchChange($event)"
         (blur)="onFocusLost()"
         [(ngModel)]="rawSearchText"
         (ngModelChange)="onChange()"
         (keydown.enter)="OnEnter($event)"
         (keydown.arrowRight)="applyHint($event)"
         (keydown.arrowUp)="selectAutocompleteUp()"
         (keydown.arrowDown)="selectAutocompleteDown()"
         (scroll)="Scrolled()"
         (selectionchange)="Scrolled()"
         #name="ngModel"
         ngControl="search"
         name="srch-term"
         #SearchField
         autocomplete="off">
  <input type="text"
         class="form-control search-hint"
         [ngModel]="SearchHint"
         name="srch-term-hint"
         #SearchHintField
         autocomplete="off">


  <div class="autocomplete-list" *ngIf="autoCompleteRenders.length > 0"
       (mouseover)="setMouseOverAutoComplete(true)" (mouseout)="setMouseOverAutoComplete(false)">
    <div class="autocomplete-item text-start"
         [ngClass]="{'autocomplete-item-selected': highlightedAutoCompleteItem === i}"
         (mouseover)="setMouseOverAutoCompleteItem(i)"
         (click)="searchAutoComplete(item)"
         *ngFor="let item of autoCompleteRenders; let i = index">
      <div>
                    <span [ngSwitch]="item.type">
                      <ng-icon *ngSwitchCase="SearchQueryTypes.caption" name="ionTextOutline"></ng-icon>
                      <ng-icon *ngSwitchCase="SearchQueryTypes.directory" name="ionFolderOutline"></ng-icon>
                      <ng-icon *ngSwitchCase="SearchQueryTypes.file_name" name="ionImageOutline"></ng-icon>
                      <ng-icon *ngSwitchCase="SearchQueryTypes.keyword" name="ionPricetagOutline"></ng-icon>
                      <ng-icon *ngSwitchCase="SearchQueryTypes.person" name="ionPersonOutline"></ng-icon>
                      <ng-icon *ngSwitchCase="SearchQueryTypes.position" name="ionLocationOutline"></ng-icon>
                      <ng-icon *ngSwitchCase="SearchQueryTypes.distance" name="ionLocationOutline"></ng-icon>
                    </span>
        {{item.preText}}<strong>{{item.highLightText}}</strong>{{item.postText}}
        <ng-icon name="ionChevronForwardOutline"
                 class="insert-button float-end"
                 (click)="applyAutoComplete(item)"
                 title="Insert"
                 i18n-title></ng-icon>
      </div>
    </div>
  </div>

</div>


