<a [routerLink]="['/search', searchQueryDTOstr]"
   style="display: inline-block;">


  <div class="photo-container rounded overflow-hidden"
       [style.width.px]="size"
       [style.height.px]="size">

    <div class="photo"
         *ngIf="thumbnail && thumbnail.Available"
         [style.background-image]="getSanitizedThUrl()"></div>

    <ng-icon *ngIf="!thumbnail || !thumbnail.Available"
             class="no-image" name="ionPersonOutline"></ng-icon>
  </div>


  <!--Info box -->
  <div class="info rounded-bottom">
    <ng-icon class="star"
             name="ionStarOutline"
             [class.favourite]="person.isFavourite"
             [class.clickable]="CanUpdate"
             (click)="CanUpdate && toggleFavourite($event)"
    ></ng-icon>
    {{person.name}} ({{person.count}})

  </div>
</a>

