<div class="row mt-1 mb-1" *ngIf="queryEntry">
  <ng-container *ngIf="IsListQuery">
    <div class="col-md-3 col-4">
      <select
        id="listSearchType"
        name="listSearchType"
        class="form-select"
        [(ngModel)]="queryEntry.type"
        (ngModelChange)="onChangeType()">
        <option *ngFor="let opt of SearchQueryTypesEnum" [ngValue]="opt.key">{{opt.key | stringifySearchType}}
        </option>
      </select>
    </div>
    <ng-container *ngIf="queryEntry.type == SearchQueryTypes.SOME_OF">
      <label class="col-4 col-sm-auto control-label" for="someOfMinValue">
        <ng-container i18n>At least this many</ng-container>
        (1-{{AsListQuery.list.length}}):</label>
      <div class="col-md col">
        <input
          type="number" min="1" [max]="AsListQuery.list.length"
          class="form-control" placeholder="1"
          title="At least this many"
          i18n-title
          [(ngModel)]="AsSomeOfQuery.min"
          (ngModelChange)="onChange()"
          name="someOfMinValue"
          id="someOfMinValue"
          required="required">
      </div>
    </ng-container>

    <ng-container *ngIf="queryEntry.type != SearchQueryTypes.SOME_OF">
      <div class="col"></div>
    </ng-container>

    <button [ngClass]="'btn-danger'"
            class="btn float-end col-1 align-self-center"
            (click)="deleteItem()">
      <ng-icon name="ionTrashOutline" title="Delete" i18n-title></ng-icon>
    </button>
    <div class="container query-list">
      <app-gallery-search-query-entry *ngFor="let sq of AsListQuery.list; index as i"
                                      [(ngModel)]="AsListQuery.list[i]"
                                      (delete)="itemDeleted(i)">
      </app-gallery-search-query-entry>
    </div>
    <div class="col query-list d-flex justify-content-start">
      <button class="btn btn-primary" (click)="addQuery()">
        <ng-icon
          class="me-1"
          style="margin-left: -0.1em; margin-right: -0.1em"
          name="ionAddOutline"
          title="Add" i18n-title></ng-icon>
        <span i18n>Add</span>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!IsListQuery">
    <div class="col-lg-4 col-xl-3">
      <div class="input-group">
        <select
          id="searchType"
          name="searchType"
          class="form-select"
          [(ngModel)]="queryEntry.type"
          (ngModelChange)="onChangeType()">
          <option *ngFor="let opt of SearchQueryTypesEnum" [ngValue]="opt.key">{{opt.key | stringifySearchType}}
          </option>
        </select>
        <select
          id="negate"
          name="negate"
          class="form-select w-auto flex-grow-0"
          title="Negate"
          p18n-title
          [(ngModel)]="SelectedMatchType"
          (ngModelChange)="onChange()">
          <option *ngFor="let mt of MatchingTypes" [ngValue]="mt">{{mt}}
        </select>
      </div>
    </div>
    <div class="col-10 col-lg" *ngIf="IsTextQuery">
      <div class="input-group">
        <input
          id="searchField"
          name="searchField"
          placeholder="Search text"
          i18n-placeholder
          class="form-control rounded-2"
          [(ngModel)]="AsTextQuery.text"
          (change)="onChange()"
          (ngModelChange)="onChange()"
          type="text"/>
      </div>
    </div>
    <ng-container [ngSwitch]="queryEntry.type">
      <div *ngSwitchCase="SearchQueryTypes.distance" class="col-10 col-lg">
        <div class="row">
          <div class="col-md-4">
            <div class="input-group">
              <input type="number" class="form-control" placeholder="1"
                     id="distance"
                     min="0"
                     step="0.1"
                     [(ngModel)]="AsDistanceQuery.distance"
                     (ngModelChange)="onChange()"
                     name="distance" required>
              <span class="input-group-text">km</span>
            </div>
          </div>
          <div class="col-md-8">
            <div class="input-group">
              <label class="control-label me-2" for="maxResolution">From</label>
              <input id="from"
                     name="from"
                     title="From"
                     placeholder="New York"
                     i18n-title
                     class="form-control input-md rounded-2"
                     [(ngModel)]="AsDistanceQuery.from.text"
                     (ngModelChange)="onChange()"
                     type="text">
            </div>
          </div>
        </div>
      </div>
      <!-- Range Search Query -->
      <div *ngSwitchCase="SearchQueryTypes.from_date" class="col-10 col-lg d-flex">
        <input id="from_date"
               name="from_date"
               title="From date"
               i18n-title
               [ngModel]="AsRangeQuery.value | date:'yyyy-MM-dd'"
               (ngModelChange)="AsRangeQuery.value = $event; onChange() "
               [value]="AsRangeQuery.value | date:'yyyy-MM-dd'" #from_date="ngModel"
               class="form-control input-md rounded-2"
               type="date">
      </div>
      <div *ngSwitchCase="SearchQueryTypes.to_date" class="col-10 col-lg d-flex">
        <input id="to_date"
               name="to_date"
               title="To date"
               i18n-title
               [ngModel]="AsRangeQuery.value | date:'yyyy-MM-dd'"
               (ngModelChange)="AsRangeQuery.value = $event; onChange() "
               [value]="AsRangeQuery.value | date:'yyyy-MM-dd'" #to_date="ngModel"
               class="form-control input-md rounded-2"
               type="date">
      </div>
      <div *ngSwitchCase="SearchQueryTypes.min_rating" class="col-10 col-lg d-flex">
        <input id="minRating"
               name="minRating"
               title="Minimum Rating"
               placeholder="0"
               i18n-title
               min="0"
               max="5"
               class="form-control input-md rounded-2"
               [(ngModel)]="AsRangeQuery.value"
               (ngModelChange)="onChange()"
               type="number">
      </div>
      <div *ngSwitchCase="SearchQueryTypes.max_rating" class="col-10 col-lg d-flex">
        <input id="maxRating"
               name="maxRating"
               title="Maximum Rating"
               placeholder="5"
               i18n-title
               min="0"
               max="5"
               class="form-control input-md rounded-2"
               [(ngModel)]="AsRangeQuery.value"
               (ngModelChange)="onChange()"
               type="number">
      </div>
      <div *ngSwitchCase="SearchQueryTypes.min_person_count" class="col-10 col-lg d-flex">
        <input id="min_person_count"
               name="min_person_count"
               title="Minimum Person count"
               placeholder="0"
               i18n-title
               min="0"
               max="5"
               class="form-control input-md rounded-2"
               [(ngModel)]="AsRangeQuery.value"
               (ngModelChange)="onChange()"
               type="number">
      </div>
      <div *ngSwitchCase="SearchQueryTypes.max_person_count" class="col-10 col-lg d-flex">
        <input id="max_person_count"
               name="max_person_count"
               title="Maximum Person count"
               placeholder="5"
               i18n-title
               min="0"
               max="5"
               class="form-control input-md rounded-2"
               [(ngModel)]="AsRangeQuery.value"
               (ngModelChange)="onChange()"
               type="number">
      </div>
      <div *ngSwitchCase="SearchQueryTypes.min_resolution" class="col-10 col-lg">
        <div class="input-group">
          <input id="minResolution"
                 name="minResolution"
                 title="Minimum Resolution"
                 placeholder="0"
                 i18n-title
                 min="0"
                 class="form-control input-md"
                 [(ngModel)]="AsRangeQuery.value"
                 (ngModelChange)="onChange()"
                 type="number">
          <span class="input-group-text">Mpx</span>
        </div>
      </div>

      <div *ngSwitchCase="SearchQueryTypes.max_resolution" class="col-10 col-lg">
        <div class="input-group">
          <input id="maxResolution"
                 name="maxResolution"
                 title="Maximum Resolution"
                 placeholder="5"
                 i18n-title
                 [min]="0"
                 class="form-control input-md"
                 [(ngModel)]="AsRangeQuery.value"
                 (ngModelChange)="onChange()"
                 type="number">
          <span class="input-group-text">Mpx</span>
        </div>
      </div>
      <div *ngSwitchCase="SearchQueryTypes.orientation" class="col-10 col-lg d-flex">
        <div class="input-group col-md-6">
          <select class="form-select rounded-2"
                  [(ngModel)]="AsOrientationQuery.landscape"
                  (ngModelChange)="onChange()"
                  id="orientation-select"
                  name="orientation-select"
                  title="Orientation"
                  required>
            <option [ngValue]="true" i18n>Landscape</option>
            <option [ngValue]="false" i18n>Portrait</option>
          </select>
        </div>
      </div>
      <div *ngSwitchCase="SearchQueryTypes.date_pattern" class="col-10 col-lg d-flex">

        <div class="row">
          <div class="input-group col-md-6">
            <span class="input-group-text" i18n>Last</span>
            <input id="daysLength"
                   name="daysLength"
                   title="Last N Days"
                   placeholder="1"
                   i18n-title
                   [min]="0"
                   class="form-control input-md"
                   [(ngModel)]="AsDatePatternQuery.daysLength"
                   (ngModelChange)="onChange()"
                   type="number">
            <span class="input-group-text" i18n>days</span>
          </div>

          <div class="input-group col-md-6">
            <input
              *ngIf="AsDatePatternQuery.frequency == DatePatternFrequency.days_ago || AsDatePatternQuery.frequency == DatePatternFrequency.weeks_ago || AsDatePatternQuery.frequency == DatePatternFrequency.months_ago || AsDatePatternQuery.frequency == DatePatternFrequency.years_ago"
              id="agoNumber"
              name="agoNumber"
              title="Ago"
              placeholder="1"
              i18n-title
              [min]="0"
              class="form-control input-md"
              [(ngModel)]="AsDatePatternQuery.agoNumber"
              (ngModelChange)="onChange()"
              type="number">
            <select class="form-select rounded-2"
                    [(ngModel)]="AsDatePatternQuery.frequency"
                    (ngModelChange)="onChange()"
                    id="date_pattern-select"
                    name="date_pattern-select"
                    title="Date Pattern"
                    required>
              <option [ngValue]="DatePatternFrequency.days_ago" i18n>Day(s) ago</option>
              <option [ngValue]="DatePatternFrequency.weeks_ago" i18n>Week(s) ago</option>
              <option [ngValue]="DatePatternFrequency.months_ago" i18n>Month(s) ago</option>
              <option [ngValue]="DatePatternFrequency.years_ago" i18n>Year(s) ago</option>
              <option [ngValue]="DatePatternFrequency.every_week" i18n>Every week</option>
              <option [ngValue]="DatePatternFrequency.every_month" i18n>Every Month</option>
              <option [ngValue]="DatePatternFrequency.every_year" i18n>Every year</option>
            </select>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-2 col-lg-1 align-self-center">
      <button [ngClass]="'btn-danger'"
              class="btn  w-auto float-end"
              (click)="deleteItem()">
        <ng-icon name="ionTrashOutline" title="Delete" i18n-title></ng-icon>
      </button>
    </div>
  </ng-container>

</div>
