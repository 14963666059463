<div [hidden]="!isVisible()" #root>

  <div class="blackCanvas"
       [style.opacity]="blackCanvasOpacity">
  </div>

  <div class="lightbox" #lightbox>
    <app-gallery-lightbox-media [gridMedia]="activePhoto ? activePhoto.gridMedia : null"
                                [nextGridMedia]="NexGridMedia"
                                [loadMedia]="!animating"
                                [zoom]="controls ? controls.Zoom : 1"
                                [drag]="controls ? controls.drag : {x:0,y:0}"
                                [windowAspect]="photoFrameDim.aspect"
                                (videoSourceError)="onVideoSourceError()"
                                #photo>
    </app-gallery-lightbox-media>

    <div class="container h-100 flex-column" *ngIf="videoSourceError && activePhoto">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-8 text-white text-center">
          <ng-icon name="ionWarningOutline" class="h2 me-2 align-bottom"></ng-icon><span class="h2" i18n>Error during loading the video.</span>
          <br/>
          <ng-container *ngIf="activePhoto.gridMedia.isVideoTranscodingNeeded()" i18n>
            Most likely the video is not transcoded.
            It can be done in the settings.
            You need to transcode these videos to watch them online:
          </ng-container>
          <ng-container *ngFor="let ext of transcodeNeedVideos;let last = last">*.{{ext}}
            <ng-container *ngIf="!last">,&nbsp;</ng-container>
          </ng-container>
        </div>
      </div>

    </div>

    <app-lightbox-controls
      *ngIf="isOpen()"
      #controls
      [activePhoto]="activePhoto"
      (closed)="hide()"
      [navigation]="navigation"
      (nextPhoto)="nextImage()"
      (previousPhoto)="prevImage()"
      (toggleInfoPanel)="toggleInfoPanel()"
      (toggleFullScreen)="toggleFullscreen()"
      [photoFrameDim]="photoFrameDim"
      [mediaElement]="mediaElement">
    </app-lightbox-controls>
  </div>

  <app-info-panel *ngIf="activePhoto && infoPanelVisible"
                  id="info-panel"
                  [style.width.px]="infoPanelWidth"
                  [media]="activePhoto.gridMedia.media"
                  (closed)="hideInfoPanel()">
  </app-info-panel>
</div>
