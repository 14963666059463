<app-gallery-map-lightbox [photos]="photos" [gpxFiles]="gpxFiles"></app-gallery-map-lightbox>
<div class="clickable"
     style="border-radius: inherit"
     id="map" #map>
  <div
    class="leaflet-map"
    style="border-radius: inherit"
    leaflet
    [leafletOptions]="options"
    [leafletLayers]="markerLayer"
    (leafletMapReady)="onMapReady($event)">
  </div>
  <div class="overlay" (click)="click()">
  </div>
</div>
