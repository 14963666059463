<div class="photo-container">
  <ng-template #popTemplate>
    <img alt="{{media.name}}"
         class="big-icon"
         [src]="thumbnail.Src"
         *ngIf="thumbnail.Available">
  </ng-template>

  <img alt="{{media.name}}"
       class="icon"
       [popover]="popTemplate"
       container="body"
       triggers="mouseenter:mouseleave"
       [src]="thumbnail.Src"
       *ngIf="thumbnail.Available">

</div>
