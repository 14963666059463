<app-gallery-search-field-base [(ngModel)]="rawSearchText"
                               (ngModelChange)="validateRawSearchText()"
                               (search)="search.emit()"
                               [placeholder]="placeholder"
                               class="rounded-2"
                               name="form-search-field">

</app-gallery-search-field-base>
<hr>
<app-gallery-search-query-entry
  [(ngModel)]="searchQueryDTO"
  (change)="onChange()"
  (ngModelChange)="onChange()"
  name="search-root"
  (delete)="resetQuery()">

</app-gallery-search-query-entry>
