<div [hidden]="!visible" #root>

  <div class="lightbox"
       [style.width.px]="lightboxDimension.width"
       [style.height.px]="lightboxDimension.height"
       [style.top.px]="lightboxDimension.top"
       [style.left.px]="lightboxDimension.left"
       [style.opacity]="opacity">
    <div
      [style.width.px]="mapDimension.width"
      [style.height.px]="mapDimension.height"

      leaflet
      [leafletOptions]="mapOptions"
      (leafletMapZoom)="onLeafletZoom()"
      (leafletMapReady)="onMapReady($event)">
    </div>
  </div>


  <div id="controllers-container" *ngIf="controllersVisible">
    <div id="controls">
      <div class="controls-background rounded-start-bottom">
        <div class="highlight control-button"
             *ngIf="fullScreenService.isFullScreenEnabled()"
             (click)="fullScreenService.exitFullScreen()" title="toggle fullscreen, key: f" i18n-title>
          <ng-icon style="margin-right: 0.2em; margin-top: -0.05em" class="align-top" size="0.9em"
                   name="ionContractOutline"></ng-icon>
        </div>

        <div class="highlight control-button"
             *ngIf="!fullScreenService.isFullScreenEnabled()"
             (click)="fullScreenService.showFullScreen(root)" title="toggle fullscreen, key: f" i18n-title>
          <ng-icon style="margin-right: 0.2em; margin-top: -0.05em" class="align-top" size="0.9em"
                   name="ionExpandOutline"></ng-icon>
        </div>
        <div class="highlight control-button" (click)="hide()" title="close, key: Escape" i18n-title>
          <ng-icon size="1.2em" name="ionCloseOutline"></ng-icon>
        </div>
      </div>

    </div>
  </div>
</div>
