<form #settingsForm="ngForm" class="form-horizontal" [id]="ConfigPath">
  <div class="card mb-4">
    <div class="card-header">
      <h5 class="d-inline-block mb-0">
        <ng-icon [name]="icon"></ng-icon>
        {{Name}}
      </h5>
      <div *ngIf="states.value.enabled !== undefined" class="float-end p-0">

        <div
          class="form-check form-switch">
          <label class="form-check-label" [for]="ConfigPath+'.enabled'" i18n>Enabled</label>

          <input class="form-check-input"
                 type="checkbox"
                 role="switch"
                 name="enabled"
                 [id]="ConfigPath+'.enabled'"
                 [disabled]="inProgress"
                 [(ngModel)]="states.value.enabled">
        </div>
      </div>
    </div>
    <div class="card-body">
      <div [hidden]="!error" class="alert alert-danger" role="alert"><strong>Error: </strong>{{error}}</div>
      <ng-container *ngIf="states.value.enabled !== false">
        <ng-container
          *ngTemplateOutlet="Recursion; context:{ rStates: states,topLevel:true,confPath:ConfigPath,skipJobs:true }"
        ></ng-container>
      </ng-container>

      <div class="panel-info"
           *ngIf="states.value.enabled === false">
        {{Name}} <span i18n>config is not supported with these settings.</span>
      </div>
      <div class="row mt-2">
        <div class="col-auto" *ngIf="states.tags?.uiJob && !states.tags?.uiJob[0].description">
          <ng-container
            *ngTemplateOutlet="JobTemplate; context:{ uiJob: states.tags?.uiJob }"
          ></ng-container>
        </div>
        <div class="col">
          <button class="btn btn-success float-end"
                  [disabled]="settingsForm.form.invalid || !changed || inProgress"
                  (click)="save()" i18n>Save
          </button>
          <button class="btn btn-secondary float-end"
                  [disabled]=" !changed || inProgress"
                  (click)="reset()" i18n>Reset
          </button>
        </div>
      </div>
      <div *ngIf="states.tags?.uiJob && !states.tags?.uiJob[0].description">
        <ng-container
          *ngTemplateOutlet="JobProcessTemplate; context:{ uiJob: states.tags?.uiJob }"
        ></ng-container>
      </div>
      <div *ngIf="states.tags?.uiJob && states.tags?.uiJob[0].description">
        <hr/>
        <ng-container
          *ngTemplateOutlet="JobTemplate; context:{ uiJob: states.tags?.uiJob }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="JobProcessTemplate; context:{ uiJob: states.tags?.uiJob }"
        ></ng-container>
      </div>
      <ng-content></ng-content>
    </div>
  </div>

  <ng-template #Recursion let-rStates="rStates" let-topLevel="topLevel" let-skipJobs="skipJobs"
               let-confPath="confPath">
    <div class="alert alert-secondary" role="alert"
         *ngIf="rStates.description && settingsService.configStyle == ConfigStyle.full">
      {{rStates.description}}
      <a *ngIf="rStates.tags?.githubIssue"
         [href]="'https://github.com/bpatrik/pigallery2/issues/'+rStates.tags?.githubIssue">
        <ng-container i18n>See</ng-container>
        #{{rStates.tags?.githubIssue}}.</a>
    </div>
    <ng-container *ngFor="let ck of getKeys(rStates)">
      <ng-container *ngIf="!(rStates.value.__state[ck].shouldHide && rStates.value.__state[ck].shouldHide())">
        <app-settings-entry
          *ngIf="(ck!=='enabled' || !topLevel) && !isExpandableConfig(rStates.value.__state[ck])"
          [name]="confPath+'_'+ck"
          [ngModel]="rStates?.value.__state[ck]">
        </app-settings-entry>
        <ng-container *ngIf="isExpandableConfig(rStates.value.__state[ck])">
          <div class="card mt-2 mb-2" *ngIf="topLevel && rStates?.value.__state[ck].tags?.uiIcon"
               [id]="ConfigPath+'.'+ck">
            <div class="card-body">
              <h5 class="card-title">
                <ng-icon [name]="rStates?.value.__state[ck].tags?.uiIcon"></ng-icon>
                {{rStates?.value.__state[ck].tags?.name || ck}}
              </h5>
              <ng-container
                *ngTemplateOutlet="Recursion; context:{ rStates: rStates.value.__state[ck], confPath:confPath+'.'+ck }"
              ></ng-container>
            </div>
          </div>
          <ng-container *ngIf="!topLevel || !rStates?.value.__state[ck].tags?.uiIcon">
            <div class="row mt-2">
              <div class="col-auto">
                <h5>{{rStates?.value.__state[ck].tags?.name || ck}}</h5>
              </div>
              <div class="col">
                <hr/>
              </div>
            </div>
            <div class="mt-2">
              <ng-container
                *ngTemplateOutlet="Recursion; context:{ rStates: rStates.value.__state[ck], confPath:confPath+'.'+ck }"
              ></ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <div *ngIf="rStates.tags?.uiJob && !skipJobs">
      <ng-container
        *ngTemplateOutlet="JobTemplate; context:{ uiJob: rStates.tags?.uiJob }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="JobProcessTemplate; context:{ uiJob: rStates.tags?.uiJob }"
      ></ng-container>
    </div>
  </ng-template>
  <ng-template #JobTemplate let-uiJob="uiJob">
    <div class="mb-2">
      <ng-container *ngFor="let job of uiJob; let i = index">
        <div class="alert alert-secondary" role="alert"
             *ngIf="job.description  && settingsService.configStyle == ConfigStyle.full">
          {{job.description}}
        </div>
        <app-settings-job-button
          *ngIf="!job.relevant || job.relevant(settingsService.settings | async)"
          class="mt-2 mb-1 mb-md-0 mt-md-0 float-left me-2"
          [soloRun]="true"
          (jobError)="error=$event"
          [allowParallelRun]="false"
          [danger]="job.job.includes('Reset') || job.job.includes('Delete')"
          [jobName]="job.job"></app-settings-job-button>
      </ng-container>

    </div>
  </ng-template>
  <ng-template #JobProcessTemplate let-uiJob="uiJob">
    <div>
      <ng-container *ngFor="let job of uiJob">
        <ng-container
          *ngIf="getProgress(job) && !job.hideProgress && (!job.relevant || job.relevant(settingsService.settings | async))">
          <hr class="mt-1"/>
          <app-settings-job-progress
            class="d-block mb-2"
            [progress]="getProgress(job)"></app-settings-job-progress>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</form>

