<div #photoContainer class="photo-container rounded" (mouseover)="mouseOver()" (mouseout)="mouseOut()">


  <img alt="{{gridMedia.media.name}}" #img [src]="thumbnail.Src"
       *ngIf="thumbnail.Available">

  <app-gallery-grid-photo-loading
    [error]="thumbnail.Error"
    [animate]="thumbnail.loading"
    *ngIf="!thumbnail.Available">
  </app-gallery-grid-photo-loading>


  <div *ngIf="gridMedia.isVideo()" class="video-indicator">
    {{gridMedia.Video.metadata.duration  | duration}}
    <ng-icon name="ionVideocamOutline"></ng-icon>
  </div>


  <!--Info box -->
  <div class="info rounded-bottom"
       *ngIf="infoBarVisible">
    <div class="photo-name">{{Title}}</div>

    <div class="photo-position" *ngIf="gridMedia.hasPositionData()">
      <ng-icon name="ionLocationOutline"></ng-icon>
      <ng-template [ngIf]="getPositionText()">
        <a [routerLink]="['/search', getPositionSearchQuery()]"
           *ngIf="searchEnabled">{{getPositionText()}}</a>
        <span *ngIf="!searchEnabled">{{getPositionText()}}</span>
      </ng-template>
    </div>

    <div class="photo-keywords" *ngIf="keywords">
      <ng-template ngFor let-keyword [ngForOf]="keywords" let-last="last">
        <a *ngIf="searchEnabled"
           [routerLink]="['/search', getTextSearchQuery(keyword.value,keyword.type)]" [ngSwitch]="keyword.type">
          <ng-template [ngSwitchCase]="SearchQueryTypes.keyword">#</ng-template><!--
       -->
          <ng-template [ngSwitchCase]="SearchQueryTypes.person">
            <ng-icon name="ionPersonOutline"></ng-icon>
          </ng-template><!--
       -->{{keyword.value}}</a>
        <span *ngIf="!searchEnabled" [ngSwitch]="keyword.type">
          <ng-template [ngSwitchCase]="SearchQueryTypes.keyword">#</ng-template><!--
       --><ng-template [ngSwitchCase]="SearchQueryTypes.person"><ng-icon
          name="ionPersonOutline"></ng-icon></ng-template><!--
       -->{{keyword.value}}</span>
        <ng-template [ngIf]="!last">,&#160;</ng-template>
      </ng-template>

    </div>
  </div>
</div>
