<app-gallery-lightbox #lightbox></app-gallery-lightbox>
<app-frame [showSearch]="showSearchBar" [showShare]="showShare">

  <ng-container navbar>
    <li class="nav-item navbar-text" *ngIf="countDown">
      <span>
        <ng-container i18n>Link availability</ng-container>
        : {{countDown.day}}
        <ng-container i18n>days</ng-container>
        ,
        {{("0" + countDown.hour).slice(-2)}}:{{("0" + countDown.minute).slice(-2)}}
        :{{("0" + countDown.second).slice(-2)}}
      </span>
    </li>
  </ng-container>

  <ng-container navbar-menu>
    <li role="menuitem" *ngIf="showRandomPhotoBuilder">
      <app-gallery-random-query-builder></app-gallery-random-query-builder>
    </li>
  </ng-container>


  <app-gallery-navbar navbar-appendix></app-gallery-navbar>
  <div body class="container-fluid">
    <ng-container *ngIf="ContentWrapper.error">
      <div class="alert alert-danger" role="alert">
        {{ContentWrapper.error}}
      </div>
    </ng-container>
    <ng-container *ngIf="!ContentWrapper.error && (ContentWrapper.searchResult || ContentWrapper.directory)">
      <!-- Show search result overflow -->
      <div class="alert alert-info" role="alert"
           *ngIf="ContentWrapper.searchResult && ContentWrapper.searchResult.resultOverflow == true" i18n>
        Too many results to show. Refine your search.
      </div>


      <app-gallery-directories class="directories"
                               [directories]="directoryContent?.directories || []"></app-gallery-directories>

      <div class="blog-map-row" *ngIf="ShowMarkDown || ShowMap">
        <app-gallery-blog
          [style.width]="blogOpen ?  '100%' : 'calc(100% - 100px)'"
          *ngIf="ShowMarkDown"
          [(open)]="blogOpen"></app-gallery-blog>
        <app-gallery-map
          class="rounded"
          [class.rounded-start-0]="ShowMarkDown"
          *ngIf="ShowMap"
          [photos]="directoryContent?.mediaGroups | photosOnly"
          [gpxFiles]="directoryContent?.metaFile | gpxFiles"></app-gallery-map>
      </div>
      <app-gallery-grid [mediaGroups]="directoryContent?.mediaGroups"
                        [lightbox]="lightbox"></app-gallery-grid>

    </ng-container>
  </div>

  <div body class="container spinner-container"
       *ngIf="(!ContentWrapper.directory ||
        ContentWrapper.directory.isPartial == true)
       && !ContentWrapper.searchResult
       && !ContentWrapper.error">
    <div class="spinner rounded">

    </div>
  </div>
</app-frame>
