<div id="controllers-container" #root>
  <div [class.dim-controls]="controllersDimmed" class="controls-caption"
       [class.controls-nodim]="lightboxService.captionAlwaysOn"
       *ngIf="Title">{{Title}}</div>

  <div [class.dim-controls]="controllersDimmed"
       class="controls controls-top">
    <div class="controls-background rounded-start-bottom">
      <div class="highlight control-button" (click)="toggleInfoPanel.emit()"
           title="info key: i" i18n-title>
        <ng-icon  name="ionInformationOutline"></ng-icon>
      </div>

      <div *ngIf="fullScreenService.isFullScreenEnabled()"
           class="highlight control-button"
           (click)="toggleFullScreen.emit()"
           title="toggle fullscreen, key: f" i18n-title>
        <ng-icon size="0.85em"
                 name="ionContractOutline"></ng-icon>
      </div>

      <div *ngIf="!fullScreenService.isFullScreenEnabled()"
           class="highlight control-button"
           (click)="toggleFullScreen.emit(true)"
           title="toggle fullscreen, key: f" i18n-title>
        <ng-icon   size="0.85em"
                 name="ionExpandOutline"></ng-icon>
      </div>

      <div class="btn-group" dropdown [insideClick]="true">
        <button id="button-basic" dropdownToggle type="button"
                class="border-0 highlight control-button"
                data-bs-auto-close="outside"
                aria-controls="dropdown-basic">
          <ng-icon size="1.2em"  name="ionMenuOutline"></ng-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
            role="menu" aria-labelledby="button-basic">
          <li role="menuitem">
            <a *ngIf="activePhoto"
               [href]="activePhoto.gridMedia.getMediaPath()"
               [download]="activePhoto.gridMedia.media.name"
               class="dropdown-item">
              <ng-icon class="me-2"
                       name="ionDownloadOutline" title="Download" i18n-title></ng-icon>
              <span i18n>Download</span>
              <small>(shift + D)</small>
            </a>
          </li>
          <li role="menuitem"
              dropdown>
            <div class="dropdown-item">
              <ng-icon class="me-2"
                       name="ionTimerOutline" title="Download" i18n-title></ng-icon>
              <span class="pe-2" i18n-title title="Slideshow playback speed" i18n>Slideshow speed:</span>
              <select
                class="form-select d-inline-block w-auto"
                [(ngModel)]="selectedSlideshowSpeed"
                (ngModelChange)="slideshowSpeedChanged()">
                <option *ngFor="let speed of playBackDurations" [value]="speed">{{ speed }}s</option>
              </select>
            </div>
          </li>
          <li role="menuitem">
            <div class="dropdown-item d-flex justify-content-between">
              <span title="key: c" i18n-title i18n>Always show captions</span>
              <div class="form-check form-switch">
                <input class="form-check-input"
                       type="checkbox"
                       role="switch"
                       name="caption-switch"
                       id="caption-switch"
                       [(ngModel)]="lightboxService.captionAlwaysOn">
              </div>
            </div>
          </li>
          <li role="menuitem">
            <div class="dropdown-item d-flex justify-content-between">
              <span title="key: a" i18n-title i18n>Always show faces</span>
              <div class="form-check form-switch">
                <input class="form-check-input"
                       type="checkbox"
                       role="switch"
                       name="faces-switch"
                       id="faces-switch"
                       [(ngModel)]="lightboxService.facesAlwaysOn">
              </div>
            </div>
          </li>
          <li
            *ngIf="activePhoto && activePhoto.gridMedia.isVideo()"
            role="menuitem">
            <div class="dropdown-item d-flex justify-content-between">
              <span title="key: l" i18n-title i18n>Loop videos</span>

              <div class="form-check form-switch">
                <input class="form-check-input"
                       type="checkbox"
                       role="switch"
                       name="loop-videos-switch"
                       id="loop-videos-switch"
                       [(ngModel)]="lightboxService.loopVideos">
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="highlight control-button"
           (click)="closeLightbox()"
           title="close, key: Escape" i18n-title>
        <ng-icon size="1.2em" name="ionCloseOutline"></ng-icon>
      </div>
    </div>
  </div>

  <div id="swipeable-container"
       (swipeleft)="zoom == 1 && nextPhoto.emit()"
       (swiperight)="zoom == 1 && previousPhoto.emit()"
       (swipeup)="zoom == 1 && closed.emit()"
       (tap)="tap($event)"
       (pan)="pan($any($event))"
       (wheel)="wheel($event)"
       (click)="mediaElement.playPause()">

    <div class="faces-container"
         [style.top.px]="photoFrameDim.height/2 + drag.y"
         [style.left.px]="photoFrameDim.width/2 + drag.x"
         [style.width.px]="faceContainerDim.width* zoom"
         [style.height.px]="faceContainerDim.height* zoom"
         *ngIf="facesEnabled && activePhoto && activePhoto.gridMedia.Photo.metadata.faces && activePhoto.gridMedia.Photo.metadata.faces.length > 0">
      <ng-container *ngIf="searchEnabled">
        <a
          class="face"
          [routerLink]="['/search', getPersonSearchQuery(face.name)]"
          [style.top.%]="face.box.top / activePhoto.gridMedia.Photo.metadata.size.height*100"
          [style.left.%]="face.box.left / activePhoto.gridMedia.Photo.metadata.size.width*100"
          [style.height.%]="face.box.height / activePhoto.gridMedia.Photo.metadata.size.height*100"
          [style.width.%]="face.box.width / activePhoto.gridMedia.Photo.metadata.size.width*100"
          *ngFor="let face of activePhoto.gridMedia.Photo.metadata.faces">
          <div class="face-box"
               [class.controls-nodim]="lightboxService.facesAlwaysOn"></div>
          <span class="face-name"
                [class.controls-nodim]="lightboxService.facesAlwaysOn">{{face.name}}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="!searchEnabled">
        <div
          class="face"
          [style.top.%]="face.box.top / activePhoto.gridMedia.Photo.metadata.size.height*100"
          [style.left.%]="face.box.left / activePhoto.gridMedia.Photo.metadata.size.width*100"
          [style.height.%]="face.box.height / activePhoto.gridMedia.Photo.metadata.size.height*100"
          [style.width.%]="face.box.width / activePhoto.gridMedia.Photo.metadata.size.width*100"
          *ngFor="let face of activePhoto.gridMedia.Photo.metadata.faces">
          <div class="face-box"
               [class.controls-nodim]="lightboxService.facesAlwaysOn">
          </div>
          <span class="face-name"
                [class.controls-nodim]="lightboxService.facesAlwaysOn"
          >{{face.name}}</span>
        </div>
      </ng-container>
    </div>


  </div>

  <div [class.dim-controls]="controllersDimmed" class="navigation-arrow navigation-arrow-left highlight rounded-end"
       *ngIf="navigation.hasPrev && zoom == 1" title="key: left arrow" id="leftArrow" i18n-title
       (click)="previousPhoto.emit()">
    <ng-icon name="ionChevronBackOutline"></ng-icon>
  </div>
  <div [class.dim-controls]="controllersDimmed" class="navigation-arrow navigation-arrow-right highlight rounded-start"
       *ngIf="navigation.hasNext && zoom == 1" title="key: right arrow" id="rightArrow" i18n-title
       (click)="nextMediaManuallyTriggered()">
    <ng-icon name="ionChevronForwardOutline"></ng-icon>
    <canvas
      *ngIf="playBackState == PlayBackStates.Play"
      #canvas width="55px" height="55px"></canvas>

  </div>

  <div [class.dim-controls]="controllersDimmed" class="controls controls-zoom row mb-3" *ngIf="Zoom > 1">
    <div class="col-1 col-md-4">
      <ng-icon name="ionRemoveOutline" class="float-end highlight"
               (click)="zoomOut()" i18n-title title="Zoom out, key: '-'"></ng-icon>
    </div>
    <input type="range"
           [(ngModel)]="Zoom" min="1" [max]="MAX_ZOOM" step="0.1"
           value="1" class="col-10 col-md-4 zoom-progress rounded ">
    <div class="col-1 col-md-4">
      <ng-icon name="ionAddOutline" class="float-start highlight"
               (click)="zoomIn()" i18n-title title="Zoom in, key: '+'"></ng-icon>
    </div>
  </div>


  <div [class.dim-controls]="controllersDimmed" class="controls controls-playback"
       *ngIf="zoom == 1 && activePhoto && activePhoto.gridMedia.isPhoto()">
    <div class="controls-background rounded-start-top pe-1 pb-1">
      <ng-icon
        *ngIf="playBackState == PlayBackStates.Play"
        name="ionPauseOutline"
        class=" highlight control-button button-active me-2 mb-2"
        (click)="pause()"
        title="Pause" i18n-title></ng-icon>

      <ng-icon
        *ngIf="playBackState == PlayBackStates.Paused"
        name="ionPlayOutline"
        class="highlight control-button me-2 mb-2"
        (click)="play()"
        title="Auto play" i18n-title></ng-icon>
    </div>
  </div>

  <div [class.dim-controls]="controllersDimmed" class="controls controls-big-play mb-3"
       *ngIf="activePhoto && activePhoto.gridMedia.isVideo() && mediaElement.Paused">
    <ng-icon name="ionPlayOutline"></ng-icon>
  </div>

  <div class="controls controls-video row mb-3" *ngIf="activePhoto && activePhoto.gridMedia.isVideo()">
    <div class="col-1">
      <ng-icon [name]="!mediaElement.Paused ? 'ionPauseOutline' :'ionPlayOutline'"
               (click)="mediaElement.playPause()"></ng-icon>
    </div>
    <input type="range" [(ngModel)]="mediaElement.VideoProgress"
           min="0" max="100" step="0.1" class="col video-progress rounded">

    <div class="col-1">
      <ng-icon [name]="mediaElement.Muted ? 'ionVolumeMuteOutline' :'ionVolumeMediumOutline'"
               (click)="mediaElement.mute()"></ng-icon>
    </div>
    <input type="range"
           [(ngModel)]="mediaElement.VideoVolume" min="0" max="1" step="0.1"
           value="1" class="col-2 col-md-1 volume rounded">
  </div>


</div>
