<ng-container *ngIf="mkObservable | async as markdowns">
  <div class="blog" *ngIf="markdowns.length > 0">
    <div class="card">
      <div class="card-body" style="min-height: 77px" [style.height]="!open ? '77px':''">
        <ng-container *ngFor="let md of markdowns; let last = last; let first = first">
          <markdown
            *ngIf="open"
            [data]="md.text">
          </markdown>
          <span *ngIf="!open && first" class="text-preview">
            <markdown
              [inline]="true"
              [data]="md.textShort">
            </markdown>
          </span>
          <hr *ngIf="open && !last">
        </ng-container>
      </div>
    </div>

    <button class="btn btn-blog-details text-body" (click)="toggleCollapsed()">
      <ng-icon [name]="open ? 'ionChevronUpOutline' : 'ionChevronDownOutline'"></ng-icon>
    </button>
  </div>
</ng-container>
