<ng-container *ngIf="Enabled">
  <div class="row mt-2">
    <div class="col-auto">
      <h5 i18n>User list</h5>
    </div>
    <div class="col">
      <hr/>
    </div>
  </div>

  <div [hidden]="!error" class="alert alert-danger" role="alert"><strong>Error: </strong>{{error}}</div>

  <table class="table table-hover">
    <thead>
    <tr>
      <th i18n>Name</th>
      <th i18n>Role</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <td>{{user.name}}</td>
      <td *ngIf="canModifyUser(user)">
        <select class="form-select" [(ngModel)]="user.role" (change)="updateRole(user)" required>
          <option *ngFor="let repository of userRoles" [value]="repository.key">
            {{repository.value}}
          </option>
        </select>
      </td>
      <td *ngIf="!canModifyUser(user)">
        {{user.role | stringifyRole}}
      </td>
      <td>
        <button [disabled]="!canModifyUser(user)" (click)="deleteUser(user)"
                [ngClass]="canModifyUser(user)? 'btn-danger':'btn-secondary'"
                class="btn float-end">
          <ng-icon name="ionTrashOutline" title="Delete" i18n-title></ng-icon>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

  <button class="btn btn-primary float-end"
          (click)="initNewUser()">
    <ng-icon name="ionAddOutline" class="me-1"></ng-icon>
    <span i18n>Add user</span>
  </button>
</ng-container>

<!-- Modal -->
<div bsModal #userModal="bs-modal" class="modal fade" id="userModal" tabindex="-1" role="dialog"
     aria-labelledby="userModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="userModalLabel" i18n>Add new User</h5>
        <button type="button" class="btn-close" (click)="userModal.hide()" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <form #NewUserForm="ngForm">
        <div class="modal-body">
          <input type="text" class="form-control" i18n-placeholder placeholder="Username"
                 [(ngModel)]="newUser.name" name="name" required>
          <input type="password" class="form-control" i18n-placeholder placeholder="Password"
                 [(ngModel)]="newUser.password" name="password" autocomplete="off" required>
          <select class="form-select" [(ngModel)]="newUser.role" name="role" required>
            <option *ngFor="let repository of userRoles" [value]="repository.key">{{repository.value}}
            </option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="userModal.hide()" i18n>Close</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal"
                  (click)="addNewUser()"
                  [disabled]="!NewUserForm.form.valid" i18n>Add User
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
