<div class="row" *ngIf="Stopped">
  <div class="col-md-2 col-12" i18n>
    Last run:
  </div>
  <div class="col-md-4 col-12" title="Run between" i18n-title>
    <ng-icon name="ionTimeOutline"></ng-icon>
    {{progress.time.start | date:'medium'}} -  {{progress.time.end | date:'mediumTime'}}
  </div>
  <div class="col-md-2 col-4"
       [title]="ProgressTitle">
    <ng-icon name="ionCheckmarkOutline"></ng-icon>
    {{progress.steps.processed + progress.steps.skipped}}/{{progress.steps.all}}
  </div>
  <div class="col-md-2 col-4" title="Status" i18n-title>
    <ng-icon name="ionPulseOutline"></ng-icon>
    {{State}}
  </div>
  <div class="col-md-2 col-4">
    <button class="btn btn-secondary float-end" (click)="openModal(template)">
      <ng-icon name="ionResizeOutline"></ng-icon>
    </button>
  </div>
</div>
<div *ngIf="Running">

  <div class="input-group mb-1">
    <input
      *ngIf="progress.state === JobProgressStates.running && progress.logs.length > 0" type="text" class="form-control"
      disabled
      [ngModel]="progress.logs[progress.logs.length-1].comment" name="details">
    <input
      *ngIf="progress.state === JobProgressStates.cancelling" type="text" class="form-control" disabled
      value="Cancelling..."
      i18n-value name="details">
    <div class="input-group-append">
      <button class="btn btn-secondary" (click)="openModal(template)">
        <ng-icon name="ionResizeOutline"></ng-icon>
      </button>
    </div>
  </div>


  <div class="mb-1 row progress-row ">
    <div class="col-6 col-md-2 col-lg-1 text-md-right order-md-0" title="time elapsed"
         i18n-title>{{TimeElapsed| duration:':'}}</div>
    <div class="col-6 col-md-2 col-lg-1 order-md-2 text-end text-md-left" title="duration"
         i18n-title>{{TimeAll| duration:':'}}</div>
    <div class="progress col-md-8 col-lg-10 order-md-1"
         [title]="ProgressTitle">
      <div
        *ngIf="progress.steps.all >0"
        class="progress-bar clickable d-inline-block progress-bar-success {{progress.state === JobProgressStates.cancelling ? 'bg-secondary' : ''}}"
        role="progressbar"
        aria-valuenow="2"
        aria-valuemin="0"
        aria-valuemax="100"
        style="min-width: 2em;"
        [style.width.%]="((progress.steps.processed+progress.steps.skipped)/progress.steps.all)*100">
        {{progress.steps.processed + progress.steps.skipped}}/{{progress.steps.all}}
      </div>
      <div
        *ngIf="progress.steps.all === 0"
        class="progress-bar d-inline-block progress-bar-success  progress-bar-striped progress-bar-animated  {{progress.state === JobProgressStates.cancelling ? 'bg-secondary' : ''}}"
        role="progressbar" aria-valuenow="100"
        aria-valuemin="0" aria-valuemax="100" style="width: 100%">
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title" >{{Name}}</h5>
    <button type="button"  class="btn-close"  (click)="modalRef.hide()" data-dismiss="modal" aria-label="Close">
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-6">
          <ng-container i18n>Processed</ng-container>
          : {{progress.steps.processed}}</div>
        <div class="col-md-3 col-6">
          <ng-container i18n>Skipped</ng-container>
          : {{progress.steps.skipped}}</div>
        <div class="col-md-3 col-6">
          <ng-container i18n>Left</ng-container>
          : {{progress.steps.all - progress.steps.skipped - progress.steps.processed}}</div>
        <div class="col-md-3 col-6">
          <ng-container i18n>All</ng-container>
          : {{progress.steps.all}}</div>
      </div>
      <div class="row  mt-3 mb-3">
        <div class="progress col-12">
          <div
            *ngIf="progress.steps.all >0"
            class="progress-bar clickable d-inline-block progress-bar-success {{progress.state !== JobProgressStates.running ? 'bg-secondary' : ''}}"
            role="progressbar"
            aria-valuenow="2"
            aria-valuemin="0"
            aria-valuemax="100"
            style="min-width: 2em;"
            [style.width.%]="((progress.steps.processed+progress.steps.skipped)/progress.steps.all)*100">
            {{((progress.steps.processed + progress.steps.skipped) / progress.steps.all) * 100 | number:'1.1-2'}}%
          </div>
          <div
            *ngIf="progress.steps.all === 0"
            class="progress-bar d-inline-block progress-bar-success  progress-bar-striped progress-bar-animated  {{progress.state !== JobProgressStates.running ? 'bg-secondary' : ''}}"
            role="progressbar" aria-valuenow="100"
            aria-valuemin="0" aria-valuemax="100" style="width: 100%">
          </div>
        </div>
      </div>
    </div>
    <div class="card bg-body-tertiary">
      <div class="card-header" i18n>
        Logs
      </div>
      <div class="card-body text-secondary">
        <ng-container *ngFor="let log of progress.logs; let i = index;">
          <p class="card-text" *ngIf="(i==0 && log.id > 0) || ( i> 0  && progress.logs[i-1].id+1!=log.id)">
            ...
          </p>
          <p class="card-text">
            #{{log.id}} [{{log.timestamp | date:'medium'}}] {{log.comment}}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
