<app-frame>

  <div body #container class="container-fluid">
    <app-album *ngFor="let album of albumsService.albums | async"
               [album]="album"
               [size]="size"></app-album>

    <div class="add-saved-search btn btn-secondary"
         *ngIf="CanCreateAlbum"
         [style.width.px]="size"
         [style.height.px]="size"
         (click)="openModal(modal)">
      <div class="text">
        <ng-icon name="ionAddOutline" class="mb-2"></ng-icon>
        <span i18n>Add saved search</span>
      </div>
    </div>

    <div class="d-flex no-item-msg"
         *ngIf="(albumsService.albums | async) && (albumsService.albums | async).length == 0">
      <div class="flex-fill">
        <h2>:(
          <ng-container i18n>No albums to show.</ng-container>
        </h2>
      </div>
    </div>
  </div>
</app-frame>


<ng-template #modal>
  <!-- sharing Modal-->
  <div class="modal-header">
    <h5 class="modal-title" i18n>Add saved search</h5>
    <button type="button"  class="btn-close"  (click)="hideModal()" data-dismiss="modal" aria-label="Close">
    </button>
  </div>
  <div class="modal-body">
    <form #savedSearchPanelForm="ngForm" class="form-horizontal">
      <div class="mb-1">
        <label for="saveSearchName">Album name</label>
        <input
          id="saveSearchName"
          name="saveSearchName"
          placeholder="Album name"
          class="form-control input-md"
          required="required"
          [(ngModel)]="savedSearch.name"
          type="text"/>
      </div>
      <div class="mb-1">
        <label for="album-search-query-builder">Search query</label>
        <app-gallery-search-query-builder
          id="album-search-query-builder"
          name="album-search-query-builder"
          [(ngModel)]="savedSearch.searchQuery">
        </app-gallery-search-query-builder>
      </div>


      <div class="input-group-btn float-end" style="display: block">
        <app-saved-search-popup-btn
          [disabled]="savedSearch.searchQuery.text == ''"
          class="me-2"
          [savedSearchDTO]="savedSearch">
        </app-saved-search-popup-btn>
        <button class="btn btn-primary" type="button"
                [disabled]="savedSearch.searchQuery.text == ''"
                (click)="saveSearch()">
          <ng-icon name="ionSaveOutline" class="me-1"></ng-icon><span i18n>Save</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
