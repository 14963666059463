<div class="content d-flex flex-column bg-body">
  <div class="modal-header">
    <h2 class="modal-title" i18n>Info</h2>
    <button type="button"  class="btn-close"  (click)="close()" aria-label="Close">
    </button>
  </div>
  <div class="row" *ngIf="contentLoaderService.isSearchResult()">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionFolderOutline"></ng-icon>
    </div>
    <div class="col-11">
      <a class="details-main dir-link"
         title="{{DirectoryPath}}"
         [routerLink]="['/gallery', DirectoryPath]"
         [queryParams]="queryService.getParams()">
        {{DirectoryPathStr}}
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionImageOutline"></ng-icon>
    </div>
    <div class="col-11">
      <div class="details-main" >
        {{media.name}}
      </div>
      <div class="details-sub text-secondary row">
        <div class="col-4">{{media.metadata.size.width}} x {{media.metadata.size.height}}</div>
        <div class="col-4" *ngIf="isPhoto()">{{calcMpx()}}MP</div>
        <div class="col-4" *ngIf="media.metadata.fileSize">{{media.metadata.fileSize | fileSize}}</div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="media.metadata.creationDate">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionCalendarOutline"></ng-icon>
    </div>
    <div class="col-11">
      <div class="details-main">
        {{ media.metadata.creationDate | date: (isThisYear() ? 'MMMM d' : 'longDate') : 'UTC' }}
      </div>
      <div class="details-sub  text-secondary row">
        <div class="col-12">{{ media.metadata.creationDate | date : 'EEEE, HH:mm:ss' : 'UTC' }}</div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="VideoData && (VideoData.duration || VideoData.bitRate)">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionVideocamOutline"></ng-icon>
    </div>
    <div class="col-11">
      <div class="details-main">
        {{"Video"}}
      </div>
      <div class="details-sub  text-secondary row">
        <div class="col-6" *ngIf="VideoData.duration">
          <ng-container i18n>duration</ng-container>
          : {{VideoData.duration | duration}}</div>
        <div class="col-6" *ngIf="VideoData.fps">
          fps: {{VideoData.fps}}/s
        </div>
        <div class="col-6" *ngIf="VideoData.bitRate">
          <ng-container i18n>bit rate</ng-container>
          : {{VideoData.bitRate | fileSize}}/s
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="CameraData">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionCameraOutline"></ng-icon>
    </div>
    <div class="col-11">
      <div class="details-main">
        {{CameraData.model || CameraData.make || "Camera"}}
      </div>
      <div class="details-sub  text-secondary row">
        <div class="col-3" *ngIf="CameraData.ISO">ISO{{CameraData.ISO}}</div>
        <div class="col-3" *ngIf="CameraData.fStop">f/{{CameraData.fStop}}</div>
        <div class="col-3" *ngIf="CameraData.exposure">
          {{toFraction(CameraData.exposure)}}s
        </div>
        <div class="col-3" *ngIf="CameraData.focalLength">
          {{CameraData.focalLength}}mm
        </div>
        <div class="col-12" *ngIf="CameraData.lens">{{CameraData.lens}}</div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="Rating">
    <div class="col-1 ps-0" title="rating" p18n-rating>
      <ng-icon class="details-icon" name="ionStarOutline"></ng-icon>
    </div>
    <div class="col-11">
      <div class="details-main" [title]="Rating + ' stars'">
        <ng-icon *ngFor="let stars of [].constructor(Rating)" class="text-body me-1" name="ionStar"></ng-icon>
        <ng-icon *ngFor="let stars of [].constructor(5-Rating)" class="text-body-tertiary me-1" name="ionStarOutline"></ng-icon>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="keywords">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionPricetagOutline"></ng-icon>
    </div>
    <div class="col-11 keywords">
      <ng-template ngFor let-keyword [ngForOf]="keywords" let-last="last">
        <a *ngIf="searchEnabled"
           [routerLink]="['/search', getTextSearchQuery(keyword.value,keyword.type)]" [ngSwitch]="keyword.type">
          <ng-template [ngSwitchCase]="SearchQueryTypes.keyword">#</ng-template><!--
       -->
          <ng-template [ngSwitchCase]="SearchQueryTypes.person"><ng-icon name="ionPersonOutline"></ng-icon></ng-template><!--
       -->{{keyword.value}}</a>
        <span *ngIf="!searchEnabled" [ngSwitch]="keyword.type">
          <ng-template [ngSwitchCase]="SearchQueryTypes.keyword">#</ng-template><!--
       --><ng-template [ngSwitchCase]="SearchQueryTypes.person"><ng-icon name="ionPersonOutline"></ng-icon></ng-template><!--
       -->{{keyword.value}}</span>
        <ng-template [ngIf]="!last">,&#32;</ng-template>
      </ng-template>
    </div>
  </div>


  <div class="row" *ngIf="hasTextPositionData() || hasGPS()">
    <div class="col-1 ps-0">
      <ng-icon class="details-icon" name="ionLocationOutline"></ng-icon>
    </div>
    <div class="col-11">
      <div class="details-main">
        {{getPositionText() || "Position"}}
      </div>
      <div class="details-sub text-secondary row" *ngIf="hasGPS()">
        <div class="col-12">
          {{PositionData.GPSData.latitude.toFixed(6)}},
          {{PositionData.GPSData.longitude.toFixed(6)}}
        </div>
      </div>
    </div>
  </div>
  <div class="mt-auto" style="height: 400px">
    <div
      *ngIf="hasGPS() && mapEnabled"

      id="map"
      leaflet
      [leafletOptions]="{zoom:10,
          center:{lat:PositionData.GPSData.latitude,
          lng:PositionData.GPSData.longitude},
          layers:[baseLayer],
          zoomControl: false}"
      [leafletLayers]="markerLayer">
    </div>
  </div>

</div>
