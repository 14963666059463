<a [routerLink]="RouterLink"
   style="display: inline-block;">


  <div class="photo-container  rounded overflow-hidden"
       [style.width.px]="size"
       [style.height.px]="size">

    <div class="photo "
         *ngIf="thumbnail && thumbnail.Available"
         [style.background-image]="getSanitizedThUrl()"></div>

    <app-icon *ngIf="!thumbnail || !thumbnail.Available" class="no-image"></app-icon>
  </div>


  <!--Info box -->
  <div class="info rounded-bottom">
    {{album.name}} ({{album.count}})
    <ng-icon *ngIf="CanUpdate && !album.locked"
             (click)="deleteAlbum($event)"
             class="info-button delete float-end"
             name="ionTrashOutline"
             title="Delete" i18n-title></ng-icon>

    <ng-icon *ngIf="album.locked"
             class="info-button float-end"
             name="ionLockClosedOutline"
             title="Album is locked, cannot be deleted from the webpage."
             i18n-title></ng-icon>

  </div>
</a>

